.info-card-section {
  --section-min-height: var(--size-9);
  --grid-columns: repeat(auto-fill, minmax(var(--size-13), 1fr));
  --grid-rows: auto;
  --gap: var(--spacing-3);

  min-height: var(--section-min-height);

  .grid {
    grid-template-columns: var(--grid-columns);
    grid-template-rows: var(--grid-rows);
    gap: var(--gap);
    align-items: center;
    padding-block: var(--spacing-3);
  }
}
