@use "@shared/ui/styles/breakpoints";

.details {
  --grid-template-rows: repeat(3, 1fr);

  @include breakpoints.md {
    --grid-template-columns: repeat(2, 1fr);
    --grid-template-rows: auto;
  }

  @include breakpoints.xl {
    --grid-template-columns: repeat(3, 1fr);
  }
}

.block {
  background-color: var(--color-background-muted);
  border-radius: var(--border-radius-3);
  padding: var(--spacing-5);
  min-height: 14rem;
}

.contact {
  &__email {
    align-items: center;
    margin-right: var(--spacing-2);
  }
  &__button {
    border: none;
    height: var(--size-4);
  }
  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: var(--ratio-square);
    flex-shrink: 0;
    padding: 0;
    border-radius: 50%;
    background-color: var(--color-secondary-a-500);
    border-color: var(--color-secondary-a-500);
    color: var(--color-brand);
    font-weight: var(--font-weight-7);
    font-size: var(--font-size-0);
    align-items: center;
    justify-content: center;
  }
}

.product {
  &__rate {
    flex-shrink: 0;
    height: 100%;
    align-items: center;
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-7);
    padding-block: var(--spacing-3);
    padding-inline: var(--spacing-4);
    background-color: var(--color-primary-b-200);
    border-radius: var(--border-radius-2);
    min-width: 5.5rem;
  }

  &__info {
    font-size: var(--font-size-0);
  }

  &__type {
    background-color: var(--color-secondary-b-200);
    color: var(--color-secondary-b-500);
    flex-shrink: 0;
  }
}

.escalate-link {
  color: var(--color-primary-b-500);
  font-size: var(--font-size-0);
  align-items: center;
  justify-content: flex-end;
  & svg {
    flex-shrink: 0;
  }
}

.more-button {
  width: fit-content;
  align-self: flex-end;
  font-size: var(--font-size-0);
  padding-inline: var(--spacing-5);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.credit-record {
  &__score {
    flex-shrink: 0;
    height: 100%;
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-7);
    padding-block: var(--spacing-3);
    padding-inline: var(--spacing-4);
    background-color: var(--color-primary-b-200);
    border-radius: var(--border-radius-2);
    min-width: 5.5rem;

    &--error {
      background-color: var(--color-primary-a-200);
    }
  }
}
