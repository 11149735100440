@use "@shared/ui/styles/breakpoints";

.container {
  background: var(--color-background-muted);
}

.back-link {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  font-size: var(--font-size-0);
  font-weight: var(--font-weight-7);
  color: var(--color-brand);

  &:hover {
    text-decoration: underline;
    color: var(--color-brand);
  }
}

.details-container {
  background-color: white;
  padding: 0 var(--spacing-4) var(--spacing-4);

  @include breakpoints.md {
    padding-block: var(--spacing-5);
    padding-inline: var(--spacing-6);
  }
}

.footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--layer-4);
  min-height: var(--size-10);
  padding: var(--spacing-4);
  background-color: var(--color-gray-000);
}
