@use "@shared/ui/styles/breakpoints";

.dashboard-header {
  position: sticky;
  top: 0;
  z-index: var(--layer-3);
  min-height: var(--size-10);
  padding: var(--spacing-3) var(--spacing-6);
  background-color: var(--color-gray-000);

  &--mobile {
    padding: var(--spacing-3) var(--spacing-4) 0;
  }

  .content {
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    min-width: 340px;
    margin-inline: auto;

    @include breakpoints.xl {
      width: min(var(--size-xl), 100%);
    }

    &--mobile {
      row-gap: var(--spacing-4);
    }

    .title {
      margin-block: var(--spacing-0);
    }

    [data-testid="chip"] {
      font-weight: var(--font-weight-6);
    }
  }
  .search {
    &--mobile {
      width: 100%;
    }
  }
}
