.application-details {
  flex-grow: 1;

  .application-card {
    align-self: stretch;
    margin-bottom: var(--spacing-6);
  }

  .application-steps-wrapper {
    margin-top: var(--spacing-7);

    .application-steps {
      --completed-icon-color: var(--color-primary-a-500);
      --active-icon-color: var(--color-primary-a-500);
      --active-label-color: var(--color-primary-a-500);
      --active-line-color: var(--color-primary-a-500);

      // override stepper label hover color
      li > div > span:hover {
        color: var(--color-primary-a-500);
      }
    }
  }

  .application-closed-lost-banner {
    width: 100%;
    margin-bottom: var(--spacing-5);
  }
}
