@use "@shared/ui/styles/breakpoints";

.navigation-header {
  position: sticky;
  top: 0;
  z-index: var(--layer-3);
  min-height: var(--size-10);
  padding: var(--spacing-3) var(--spacing-6);
  background-color: var(--color-gray-000);

  &--mobile {
    top: var(--size-9);
    min-height: auto;
    padding-inline: var(--spacing-5);

    @include breakpoints.md {
      top: 0;
      padding-block: var(--spacing-5);
    }
  }

  .link {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    font-size: var(--font-size-0);
    font-weight: var(--font-weight-7);
    color: var(--color-brand);

    &:hover {
      text-decoration: underline;
      color: var(--color-brand);
    }

    &--mobile {
      font-size: var(--font-size-00);

      @include breakpoints.md {
        font-size: var(--font-size-0);
      }
    }
  }
}
