.contact-button--active {
  box-shadow: var(--shadow-medium);
  background-color: var(--color-primary-a-600);
}

.menu {
  position: absolute;
  inset: calc(100% + var(--spacing-2)) var(--spacing-0) auto auto;
  min-width: var(--size-14);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-4);
  border-radius: var(--border-radius-2);
  background-color: var(--color-white);
  box-shadow: var(--shadow-medium);
  color: var(--color-brand);

  .read-only-field {
    & > span:first-child {
      font-size: var(--font-size-00);
      font-weight: var(--font-weight-4);
    }

    & > span:last-child {
      font-size: 12px;
      font-weight: var(--font-weight-7);
      color: var(--color-brand);
    }

    &--underline > span:last-child {
      text-decoration: underline;
    }
  }

  .clipboard-button--disabled:hover {
    box-shadow: none !important;
  }
}
