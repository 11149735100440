.info-card-skeleton {
  .section {
    &.first-section {
      --grid-columns: 1fr;

      [data-testid="skeleton-element"] {
        min-width: var(--size-12);
      }
    }
  }
}
