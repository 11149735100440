.application-stepper {
  --completed-icon-color: var(--color-brand);
  --active-icon-color: var(--color-brand);
  --active-label-color: var(--foreground-default);
  --active-line-color: var(--color-brand);
  --max-content-size: var(--size-15);

  display: inline-flex;
  margin-top: var(--spacing-7);
}
