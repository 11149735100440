@use "@shared/ui/styles/breakpoints";

.clipped-content {
  --clipped-content-padding: var(--spacing-4) var(--spacing-6) var(--spacing-6);
  --content-width: 100%;

  padding: var(--clipped-content-padding);

  @include breakpoints.lg {
    --content-width: min(var(--size-lg), 100%);
  }

  @include breakpoints.xl {
    --content-width: min(var(--size-xl), 100%);
  }

  &--mobile {
    --clipped-content-padding: var(--spacing-4);
  }

  .content {
    width: var(--content-width);
    min-width: var(--content-min-width);
    margin-inline: auto;
  }
}
