.info-card {
  --card-max-width: 1062px;
  --card-padding: var(--spacing-4) var(--spacing-6);
  --card-shadow: none;
  --card-shadow-hover: none;

  max-width: var(--card-max-width);
  background-color: var(--color-tertiary-a-200);

  .section-divider {
    --divider-size: calc(var(--border-width-1) / 2);

    margin-block: var(--spacing-0);
    background-color: var(--color-white);
  }
}
