.progress-bar {
  position: relative;
  z-index: var(--layer-2);

  &__stepper--closed:first-child:before {
    border-color: var(--color-primary-a-500);
  }

  ul {
    margin: 0;
  }
}
