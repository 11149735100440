@use "@shared/ui/styles/breakpoints";

.application-header {
  position: sticky;
  top: var(--size-9);
  z-index: var(--layer-4);
  min-height: var(--size-10);
  padding: var(--spacing-4);
  background-color: var(--color-gray-000);

  @include breakpoints.md {
    top: 0;
    padding: var(--spacing-3) var(--spacing-6);
    border-bottom: 1px solid var(--color-background-subtle);
  }

  &__progress-bar:before {
    width: 18px !important;
    height: 18px !important;
    margin-top: 6px !important;
  }

  &__progress-tracker {
    padding: 0;
  }
}
